import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Contributions.module.scss"

function Contributions({strapiData}) {
  console.log("Contributions Data:", strapiData)
  
  // Check if cards exists in strapiData
  const cardsData = strapiData?.cards || []
  console.log("Cards Data:", cardsData)

  return (
    <Container>
   
        <Row>
          <h2 
            className={styles.contributionsHeading}    
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          
          {Array.isArray(cardsData) && cardsData.map((card, index) => (
            <Col key={index} md={4} lg={4} sm={12} xs={12}>
              <div className={styles.contributionsCard}>
                <h3 className={styles.contributionsCardHeading}>
                  {card.title}
                </h3>
                <div 
                  className={styles.contributionsCardText}
                  dangerouslySetInnerHTML={{ 
                    __html: card.description?.description 
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
     
    </Container>
  )
}

export default Contributions